import React from 'react';
import { Grid, Loader } from "semantic-ui-react";


const Loading = props => {
    return (
        <React.Fragment>
            {/* {props.loading 
                ? ( */}
                    <Grid verticalAlign="middle" style={{ height: props.height, marginTop: props.marginTop }}>
                    <Grid.Column>
                        <Loader active inline="centered" />
                    </Grid.Column>
                    </Grid>
                    {/* ) 
                : null
            } */}
        </React.Fragment>
    )
}

export default Loading;