import React, { Component } from "react";
import Loading from './components/loader';
import AppConfig from './appConfig';
import razorPayKey from "./components/razorPay";
import webLoginLogo from "./images/web-login-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import axios from 'axios';

class App extends Component {

  state = {
    isLoading: false,
  }

  componentDidMount() {
    this.getResultsForPayment();
  }

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  parseToken = (token) => {
    return JSON.parse(
      token.replace(/^[^]+\.([^]+)\.[^]+$/, (token, payload) =>
        Buffer.from(payload, "base64").toString("ascii")
      )
    );
  };

  callBridgeToAndroid = () => {
    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_APP_LAUNCH,'',''`
      );
    }

    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_APP_LAUNCH`
      );
    }
  }

  getResultsForPayment = () => {
    let tokenDetails = this.parseToken(this.getQuery()["token"])
    this.setState({
      isLoading: true
    })
    axios
    .get(`${AppConfig.BASE_URL}/v3/inapp/renewal/details?orgId=${tokenDetails.orgId}`, {
      // .get(`https://apip.classplusapp.com/v3/inapp/renewal/details?orgId=${tokenDetails.orgId}`, {
        headers: {
          "Api-Version": 11,
          "x-access-token": this.getQuery()["token"],
        },
      })
      .then((response) => {
        this.setState({
          isLoading: false
        })
        this.callRazorPaySections(response.data.data,tokenDetails);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        toast.error(error);
        console.error(error);
      });
  }

  callRazorPaySections = (response,tokenDetails) => {
    let _this = this;
    const options = {
      key: razorPayKey,
      amount: parseInt(response.fullAmount)*100,
      image: webLoginLogo,
      currency: "INR",
      notes: {
        orgId: tokenDetails.orgId,
        deviceType: /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? "ANDROID"
          : /iPhone|iPad|iPod/i.test(navigator.userAgent)
            ? "IOS"
            : "WEB",
        type: "inAppRenewal",
        userId: tokenDetails.id,
        amount: parseInt(response.fullAmount)*100
      },
      handler(responseAgain) {
        _this.setState({
          isLoading: true
        })
        let params = {
          amount: parseInt(response.fullAmount)*100,
          transactionId: responseAgain.razorpay_payment_id
        };
        axios
        .post(`${AppConfig.BASE_URL}/v3/inapp/renewal/payment/verify`, params, {
          // .post(`https://apip.classplusapp.com/v3/inapp/renewal/payment/verify`, params, {
            headers: {
              "Api-Version": 11,
              "x-access-token": _this.getQuery()["token"],
            },
          })
          .then((response) => {
            toast.success('Payment successful');

            _this.setState({
              isLoading: false
            },() => {
              setTimeout(() => {
                toast.info('Please go back to the application and refresh your page.',{
                  autoClose: 50000
                } );

              },1000)
            }
            )
            _this.callBridgeToAndroid();
          })
          .catch((error) => {
            toast.error('Payment Unsuccessful');
            _this.setState({
              isLoading: false
            },()=>{
              _this.callBridgeToAndroid();
            })
            console.error(error);
          });
      },
      prefill: {
        contact: tokenDetails.mobile,
        email: tokenDetails.email,
      },
    };
    _this.instance = new window.Razorpay(options);
    _this.instance.open();
  }

  render() {
    return (
      <div>
        <ToastContainer autoClose={2000} />
        <Loading height="100vh" marginTop="-20px" />
      </div>
    );
  }
}

export default App;
