let BASE_URL;
console.log("process.env",process.env);
if (process.env.NODE_ENV == "development") {
  BASE_URL = "https://apip.classplusapp.com";
} else if (process.env.NODE_ENV == "production") {
  BASE_URL = "https://api.classplusapp.com";
}
 else {
  if (process.env.REACT_APP_BUILD_ENV == "staging") {
    BASE_URL = "https://apip.classplusapp.com";
  } else if (process.env.REACT_APP_BUILD_ENV == "production") {
    BASE_URL = "https://api.classplusapp.com";
  }
}

export default {
  BASE_URL,
};
